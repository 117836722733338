import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer-container" style={{ backgroundImage: "url(footer.jpeg)" }} >
            <div className="overlay"></div>
            <div className="footer">
                <div>
                    <img src="finallogo1.png" alt="" style={{ width: "270px", marginBottom: "30px" }} />
                    {/* <p>Expedite your recruitment <br />
                        and secure top-tier talent <br />
                        quickly and effortlessly.</p> */}

                    <div className="logos">
                        <img src="facebook.svg" alt="" />
                        <img src="insta.svg" alt="" />
                        <img src="twitter.svg" alt="" />
                        <img src="linkedIn.svg" alt="" />
                        <img src="youtube.svg" alt="" />
                    </div>
                </div>
                <div style={{ width: "270px" }}>
                    <h1>Quick Links</h1>
                    <div className="d-flex flex-column gap-3">
                        <div>
                            <img src="bullet.svg" alt="" />
                            <Link>About Us</Link>
                        </div>
                        <div>
                            <img src="bullet.svg" alt="" />
                            <Link>Our Services</Link>
                        </div>
                        <div>
                            <img src="bullet.svg" alt="" />
                            <Link>Corporate Governance</Link>
                        </div>
                        <div>
                            <img src="bullet.svg" alt="" />
                            <Link>Get in Touch</Link>
                        </div>
                        <div>
                            <img src="bullet.svg" alt="" />
                            <Link>Home</Link>
                        </div>
                    </div>
                </div>
                <div style={{ width: "270px" }}>
                    <h1>Contact Us</h1>

                    <div className="d-flex flex-column gap-3">
                        <div>
                            <Link className="m-0">DSO - IFZA Properties, <br /> Dubai UAE <br /> P.O Box 44885</Link>
                        </div>
                        <div>
                            <img src="web.svg" alt="" />
                            <Link>www.sp-viva.com</Link>
                        </div>
                        <div>
                            <img src="email.svg" alt="" />
                            <Link> info@spurviva.com</Link>
                        </div>
                        <div>
                            <img src="phone.svg" alt="" />
                            <Link> +974 3329 9737</Link>
                        </div>
                        <div>
                            <img src="phone.svg" alt="" />
                            <Link> +33 624320247</Link>
                        </div>
                    </div>
                </div>
            </div>

            <p className="copyright">Copyright © 2024 | Spurviva all rights reserved.</p>
        </div>
    );
}

export default Footer;